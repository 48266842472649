import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import BlogCards from "components/blog/BlogCards";
import icon from "images/svg_icons/government.svg";
import { graphql } from "gatsby";
import LargeTitle from "components/services/LargeTitle";

export default ({ data }) => (
  <>
    <Meta url="/government-policies-china/" />
    <Nav />
    <LargeTitle
      size="small"
      img={icon}
      green="Official Chinese "
      black="Government Policies"
      text={
        <>
          Here you will find English translations of official Chinese government
          laws, policies, and press releases related to mobile technology -
          particularly mobile apps and mobile games in China. Each includes a
          link to the original post on the web.
          <br />
          <br />
          These are useful for developers who are publishing apps for the
          Chinese market, to ensure they are compliant with the latest laws and
          regulations.
        </>
      }
    />
    <BlogCards category="government-documents" posts={data.allWpPost.edges} />
    <CTA />
    <Footer />
  </>
);

export const pageQuery = graphql`
  query {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { eq: "government-documents" } } }
        }
      }
    ) {
      edges {
        node {
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          custom_data {
            metaDescription
            previewDescription
            previewTitle
          }
          slug
          title
          date
        }
      }
    }
  }
`;
